.Dots {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;
    z-index: 999;
    ul {
        list-style: none;
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 auto;
        li {
            padding: 0 20px;
            width: 20px;
            height: 20px;
            button {
                background: none;
                border: none;
                outline: none;
                > * {
                    color: rgba(255, 255, 255, 0.75);
                }
            }
        }
    }
}
