.Page1 {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: 64px 5% 10% 1fr 10%;
    grid-template-columns: 10% 1fr 10%;
    z-index: 1;
    .info {
        display: flex;
        width: 80%;
        margin: 0 auto;
        justify-content: center;
        flex-wrap: wrap;
        img {
            margin: auto 0;
            max-width: 256px;
            height: 256px;
            border-radius: 10px;
            margin-right: 20px;
        }
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            min-width: 256px;
            li {
                h3,
                h4 {
                    &:not(b) {
                        font-weight: 200;
                    }
                    a,
                    a:visited,
                    a:hover {
                        color: white;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
