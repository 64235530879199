.Home {
    header {
        width: 80%;
        display: flex;
        align-items: center;
        .headline {
            flex: 1;
            text-align: left;
            position: relative;
            padding: 20px;
            justify-self: flex-start;
            flex-wrap: wrap;
            h2 {
                animation-delay: 1s;
                animation-fill-mode: forwards;
                animation-name: FadeInDown;
                animation-duration: 1s;
                animation-timing-function: ease-out;
                opacity: 0;
                font-size: 4rem;
                line-height: 1;
            }
            h1 {
                animation-delay: 0s;
                animation-fill-mode: forwards;
                animation-name: FadeInDown;
                animation-duration: 1s;
                animation-timing-function: ease-out;
                opacity: 0;
                font-size: 4rem;
                font-weight: 200;
                line-height: 1;
            }
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: -20px;
                background: white;
                animation-delay: 0s, 2s;
                animation-fill-mode: forwards, forwards;
                animation-name: ExpandHeight, ExpandWidth;
                animation-duration: 2s, 1s;
                animation-timing-function: ease-in-out, ease-in-out;
            }
        }
        .right-arrow {
            opacity: 0;
            animation-delay: 0.5s;
            animation-fill-mode: both;
            animation-name: FadeInDown;
            animation-duration: 1s;
            animation-timing-function: ease-out;
            button {
                background: none;
                outline: 0;
                border: none;
                color: white;
                transition: opacity 250ms ease-out;
                font-size: 2rem;
                &:hover {
                    opacity: 0.8;
                }
                &.hide {
                    opacity: 0;
                }
            }
        }
    }
}

@keyframes FadeInUp {
    0% {
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes FadeInDown {
    0% {
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes ExpandHeight {
    0% {
        width: 1px;
        height: 0;
    }
    100% {
        width: 1px;
    }
}
@keyframes ExpandWidth {
    0% {
        left: -20px;
    }
    100% {
        left: 0;
    }
}
