.nav-container {
    position: absolute;
    height: 100%;
    z-index: 999;
    width: 0;
    nav {
        z-index: 999;
        display: flex;
        transition: all 500ms ease-out;
        transition-delay: 0.5s;
        width: 200px;
        justify-content: space-evenly;
        align-items: center;
        transform: scaleX(1);
        &.open {
            transform: scaleX(1);
        }
        .openButton {
            background: none;
            height: 64px;
            width: 64px;
            line-height: 64px;
            font-size: 2rem;
            color: white;
            border: none;
            outline: none;
            z-index: 999;
            text-align: center;
            transition: transform 500ms ease-out, width 500ms ease-out, color 500ms ease-out;
            z-index: 999;
            &:hover {
                color: lightgray;
            }
        }
        h4 {
            flex: 2;
            line-height: 64px;
            opacity: 0;
            transition: all 500ms ease-out;
            margin: 0;
            pointer-events: none;
            text-align: center;
            justify-self: center;
            z-index: 998;
            transform: translateX(-200px);
        }
        &.open > h4 {
            pointer-events: all;
            opacity: 1;
            transform: translateX(-20px);
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            background: rgb(14, 14, 14);
            transition: all 500ms ease-out;
        }
        &.open::after {
            width: 200px;
        }
    }
    .page-list {
        width: 200px;
        top: 0;
        position: absolute;
        height: 100%;
        transform: translateX(-100%);
        z-index: -1;
        transition: all 500ms ease-out;
        ul {
            z-index: 98;
            height: 100%;
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            background: black;
            li {
                text-align: center;
                display: inline;
                transform: translateX(-20px);
                opacity: 0;
                button {
                    background: none;
                    font-size: 2rem;
                    color: white;
                    border: none;
                    outline: none;
                    padding: 20px 0;
                    width: 100%;
                }
                &:nth-child(1) {
                    transition: transform 250ms ease-out 100ms, opacity 250ms ease-out 100ms, background 250ms ease-out 0s;
                }
                &:nth-child(2) {
                    transition: transform 250ms ease-out 200ms, opacity 250ms ease-out 200ms, background 250ms ease-out 0s;
                }
                &:nth-child(3) {
                    transition: transform 250ms ease-out 300ms, opacity 250ms ease-out 300ms, background 250ms ease-out 0s;
                }
                &:nth-child(4) {
                    transition: transform 250ms ease-out 400ms, opacity 250ms ease-out 400ms, background 250ms ease-out 0s;
                }
                &:hover {
                    background: rgb(20, 20, 20);
                }
            }
            &.open {
                > li {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }
        &.open {
            transform: translateX(0);
        }
    }
    &.open {
        width: 200px;
    }
}
