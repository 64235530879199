.Page2 {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: 64px 5% 10% 1fr 10%;
    grid-template-columns: 10% 1fr 10%;
    z-index: 1;
    .info {
        grid-row: 4/5;
        grid-column: 2/3;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        div {
            margin: 0 20px;
            width: 400px;
            ul {
                margin: 0;
                padding: 1rem;
            }
            h3 {
                text-decoration: underline;
            }
        }
    }
}
@import "sizes";
