@import "~bootstrap/scss/bootstrap";

main {
    color: white;
    background: black;
    .content {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 100vh;
        overflow: hidden;
        transition: transform 500ms ease-out;
        .page {
            transition: transform 1s cubic-bezier(0.22, 0.61, 0.36, 1);
            grid-column: 1/2;
            grid-row: 1/2;
        }
    }
    a {
        position: relative;
        &::after {
            content: "";
            height: 2px;
            position: absolute;
            background: white;
            left: 0;
            top: 100%;
            width: 0%;
            transition: width 300ms ease-out;
        }
        &:hover {
            &::after {
                width: 100%;
            }
        }
    }
    .page {
        background: rgb(32, 32, 32);
        width: 100%;
        height: 100vh;
        display: grid;
        grid-template-rows: 8% 10% 1fr 10%;
        grid-template-columns: 10% 1fr 10%;
        z-index: 1;
        overflow: hidden;
        .info {
            grid-row: 3/4;
            grid-column: 1/4;
            display: flex;
            opacity: 0;
            transform: translateY(-100px);
            transition: all 1s ease-out;
            &.active {
                opacity: 1;
                transform: translateY(0);
            }
        }
        .page-navigation {
            background: rgb(20, 20, 20);
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.425);
            display: grid;
            grid-template-columns: 10% 1fr 10%;
            grid-template-rows: 1fr;
            grid-row: 2/3;
            grid-column: 1/4;
            h2 {
                grid-column: 2/3;
                font-size: 2rem;
                font-weight: 200;
                align-self: center;
                margin: 0;
            }
            .prevButton,
            .nextButton {
                background: none;
                border: none;
                outline: none;
                grid-row: 1/2;
                color: white;
                font-size: 2rem;
                & > * {
                    transition: transform 500ms ease-out;
                }
            }
            .prevButton {
                grid-column: 1/2;
                &:hover > * {
                    transform: translateX(-10px);
                }
            }
            .nextButton {
                grid-column: 3/4;
                &:hover > * {
                    transform: translateX(10px);
                }
            }
        }
        &.Home {
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgb(20, 20, 20);
            z-index: 1;
        }
    }
}

.showing {
    transition: transform 1s ease-in-out;
    transform: translateX(0) !important;
}
.out {
    transition: transform 1s ease-in-out;
    transform: translateX(-100%) !important;
}
.in {
    transition: transform 1s ease-in-out;
    transform: translateX(100%) !important;
}
